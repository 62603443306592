/* Hamburger menu styles */
.hamburger-menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    background-color: #333;
    color: white;
    padding: 10px;
    position: relative;
    max-width: 100%;
    width: 100px;
  }
  
  .menu-button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 24px;
    margin-right: 10px;
    display: inline-block;
  }
  
  .bar {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: transform 0.3s ease-in-out;
  }
  
  .menu-drawer {
    width: 0;
    height: 100%;
    background-color: #333;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
  }
  
  /* Add this .hamburger-menu.open class */
  .hamburger-menu.open {
    display: flex;
  }
  
  .menu-drawer.open {
    width: 250px;
  }
  
  .menu-items {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .menu-items li {
    padding: 10px;
  }
  
  /* Styles for the hamburger menu at max-width: 700px */
  @media (max-width: 700px) {
    .hamburger-menu {
      display: flex !important;
      width: 100%;
    }
    .menu-button {
      display: block;
    }
  
    .menu-button.open .bar:nth-child(1) {
      transform: rotate(-45deg) translateY(4px);
    }
  
    .menu-button.open .bar:nth-child(2) {
      opacity: 0;
    }
  
    .menu-button.open .bar:nth-child(3) {
      transform: rotate(45deg) translateY(-4px);
    }
  
    .menu-drawer {
      width: 0;
    }
  
    .menu-drawer.open {
      width: 100%;
    }
  
    .menu-items {
        display: flex;
        justify-content: center;
        flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 20px 0;
    }
    .content {
        width: 100%;
    }
    .menu-items li {
      padding: 10px;
    }
  }
  