* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  height: 100%;
  width: 100%;
  background-color: #252525;
}


.header  {
  position: fixed;
  margin: 0 auto;
}
a {
  text-decoration: none;
  color: rgb(199, 253, 189);
}
 ul.header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 75px;
  margin: 0;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background: linear-gradient(to bottom, #444, #333); /* Add the gradient background */
}
.header, li{  
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none !important
}

.header li:hover a {
  color: rgb(2, 246, 47); /* Change the text color on hover to the desired color */
}

.language-switcher {
position: fixed;
margin-top: 7vh;
}



@keyframes shine {
  to {
    background-position: -200% -200%;
  }
}



.logo {
  max-width: 100px; /* Adjust the maximum width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Add margin to separate the logo from the links */
}


.homeP2 {
  overflow: auto; /* Enable overflow for the "Vision" paragraph */
  max-height: 200px; /* Set a maximum height for the "Vision" paragraph before scrolling */
}
@media (max-width: 700px) {
  .App {
    height: 100%;
    max-width: 100%;
    background-color: #252525;
    overflow-x: hidden; 
  }
  .header {
      display: none !important;
  } 
  .hamburger-menu {
    display: flex !important;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}



